.icon-play {
    width: 96px;
    height: 96px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 768px) {
        width: 74px;
        height: 74px;
    }
    img {
        width: 100%;
        height: 100%;
    }
}

.video-wrapper {
    .icon-mute {
        transition: opacity 0.1s ease-in-out;
        opacity: 0;
    }
    &:hover {
        .icon-mute {
            opacity: 1;
        }
    }
}
