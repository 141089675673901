.animContainer {
    position: relative;
    top: 150px;
    margin-bottom: 0;
    height: 400px;
}

.animHeaderFirst, .animHeaderSecond, .animHeaderThird {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    em {
        font-size: 64px;
        color: #1B365D;
    }
}

.animHeaderFirst {
    opacity: 0;
}

.animHeaderSecond {
    opacity: 0;
}

.animHeaderThird {
    opacity: 0;
}

.slideIn {
    transition: 0.6s ease all;
    transform: translateY(-30px);
    opacity: 1;
}

.slideOut {
    transition: 0.6s ease all;
    transform: translateY(30px);
    opacity: 0;
}