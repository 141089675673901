.animation-wrapper-variables {
    // scale
    --x-small: scale3D(0.25, 0.25, 0.25);
    --small: scale3D(0.5, 0.5, 0.5);
    --medium: scale3D(0.75, 0.75, 0.75);
    --large: scale3D(1, 1, 1);

    // opacity
    --hidden: 0;
    --almost-hidden: 0.25;
    --half: 0.5;
    --full: 1;

    --transition-speed: 0.4s;
    --transition: all var(--transition-speed) ease-in-out;
}
