.text-animation-wrapper {
    transition: var(--transition);

    & blockquote {
        text-indent: -0.4em;
    }

    & * {
        transition: var(--transition);
    }

    > :first-child {
        opacity: var(--full);
    }
    > :nth-child(2) {
        opacity: var(--hidden);
    }
    > :last-child {
        opacity: var(--hidden);
    }

    &.two {
        > :first-child {
            opacity: var(--hidden);
        }
        > :nth-child(2) {
            opacity: var(--full);
        }
        > :last-child {
            opacity: var(--hidden);
        }
    }
    &.three,
    &.out {
        > :first-child {
            opacity: var(--hidden);
        }
        > :nth-child(2) {
            opacity: var(--hidden);
        }
        > :last-child {
            opacity: var(--full);
        }
    }
}

.images1-animation-wrapper {
    transition: var(--transition);

    & * {
        transition: var(--transition);
    }

    transform: translate3D(0, 0, 0);

    > :first-child {
        opacity: var(--full);
    }
    > :nth-child(2) {
        opacity: var(--hidden);
    }
    > :last-child {
        opacity: var(--hidden);
    }

    &.one {
        transform: translate3D(0, 10%, 0);

        > :first-child {
            opacity: var(--full);
        }
        > :nth-child(2) {
            opacity: var(--hidden);
        }
        > :last-child {
            opacity: var(--hidden);
        }
    }
    &.two {
        transform: translate3D(0, 50%, 0);

        > :first-child {
            opacity: var(--full);
        }
        > :nth-child(2) {
            opacity: var(--full);
        }
        > :last-child {
            opacity: var(--hidden);
        }
    }
    &.three,
    &.out {
        transform: translate3D(0, 80%, 0);
        > :first-child {
            opacity: var(--full);
        }
        > :nth-child(2) {
            opacity: var(--full);
        }
        > :last-child {
            opacity: var(--full);
        }
    }
}

.images2-animation-wrapper {
    transition: var(--transition);

    & * {
        transition: var(--transition);
    }

    transform: translate3D(0, 0, 0);

    > :first-child {
        opacity: var(--full);
    }
    > :nth-child(2) {
        opacity: var(--hidden);
    }
    > :last-child {
        opacity: var(--hidden);
    }

    &.one {
        transform: translate3D(0, -10%, 0);

        > :first-child {
            opacity: var(--full);
        }
        > :nth-child(2) {
            opacity: var(--hidden);
        }
        > :last-child {
            opacity: var(--hidden);
        }
    }
    &.two {
        transform: translate3D(0, -50%, 0);

        > :first-child {
            opacity: var(--full);
        }
        > :nth-child(2) {
            opacity: var(--full);
        }
        > :last-child {
            opacity: var(--hidden);
        }
    }
    &.three,
    &.out {
        transform: translate3D(0, -80%, 0);
        > :first-child {
            opacity: var(--full);
        }
        > :nth-child(2) {
            opacity: var(--full);
        }
        > :last-child {
            opacity: var(--full);
        }
    }
}
