.loginForm {
    position: relative;
    top: 100px;
    max-width: 500px;
    margin: auto;
    text-align: center;
    border-radius: 4px;
    padding: 40px;
    background-color: #fafafa;
    > p {
        font-weight: 500;
        font-size: 18px;
    }
    .testing {
        border-top: 1px solid gainsboro;
        margin-top: 30px;
        padding-top: 30px;
    }

    button {
        margin-top: 15px;
    }
}