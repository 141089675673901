.hero-animation-wrapper {
    transition: var(--transition);
    opacity: var(--half);

    & span {
        transition: var(--transition);
        color: #284e80;
    }

    :first-child {
        transform: translate3D(0, 200%, 0) var(--medium);
        opacity: var(--full);
    }
    :nth-child(2) {
        transform: translate3D(0, 100%, 0) var(--small);
        opacity: var(--hidden);
    }
    :last-child {
        transform: translate3D(0, 0, 0) var(--small);
        opacity: var(--hidden);
    }

    &.one,
    &.two,
    &.three,
    &.out {
        opacity: var(--full);
    }

    &.one {
        :first-child {
            transform: translate3D(0, 100%, 0) var(--large);
            opacity: var(--full);
        }
        :nth-child(2) {
            transform: translate3D(0, 100%, 0) var(--medium);
            opacity: var(--half);
        }
        :last-child {
            transform: translate3D(0, 0, 0) var(--small);
            opacity: var(--hidden);
        }
    }
    &.two {
        :first-child {
            transform: translate3D(0, 0, 0) var(--medium);
            opacity: var(--half);
        }
        :nth-child(2) {
            transform: translate3D(0, 0, 0) var(--large);
            opacity: var(--full);
        }
        :last-child {
            transform: translate3D(0, 0, 0) var(--medium);
            opacity: var(--half);
        }
    }
    &.three,
    &.out {
        :first-child {
            transform: translate3D(0, -75%, 0) var(--small);
            opacity: var(--almost-hidden);
        }
        :nth-child(2) {
            transform: translate3D(0, -100%, 0) var(--medium);
            opacity: var(--half);
        }
        :last-child {
            transform: translate3D(0, -100%, 0) var(--large);
            opacity: var(--full);
        }
    }
}
