html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

body,
html {
    position: relative;
    margin: 0;
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    color: #000000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
    overflow: auto;
}

#fbi-root {
    width: 100%;
    height: 100%;

    --gray2: #4f4f4f;
    --gray3: #828282;
    --gray4: #bdbdbd;

    a {
        text-decoration: none;
    }

    h1,
    h2 {
        line-height: normal;
    }

    h1 {
        font-size: 44px;
    }

    h2 {
        font-size: 36px;
    }

    h3 {
        font-size: 28px;
    }

    h4 {
        font-size: 24px;
    }

    h5 {
        font-size: 20px;
    }

    p {
        @media screen and (min-width: 769px) {
            max-width: 680px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    button {
        @media screen and (min-width: 769px) {
            display: inline-block;
            width: auto;
        }
    }
}
